<template>
  <div class="col-lg-6 g-4  mb-0">
    <div class="row g-3 cards-list">
      <div class="col-lg-6">
        <dashboard-card
            :isLoading="loading"
            :title="{ar: 'عدد الزوار الآن', key: 'active_users'}"
            :filterable="false"
            :amount="active_users || 0"
        >
          <el-icon><i class="las la-user"></i></el-icon>
        </dashboard-card>

        <div class="mt-3 custom-height">
          <el-card dir="ltr" class="box-card dashboard-card">
            <div class="text-end pt-3 px-3" dir="rtl">
              <DatesFilterDropDown @triggerFilter="refetchInfo"/>
            </div>
            <div class="card-text" v-if="!loading && targetCardToRefetch !== 'info'">
              <div class="row">
                <div class="col-lg-5 col-12" v-if="statistics.operation_system?.length">

                  <h4 class="card-title mb-1 card-info-title" style="color: #5a7e04" title="Operating system">OS</h4>
                  <template :key="os.operation_system" v-for="os in statistics.operation_system">
                    <span class="text-small d-block">
                      <span class="list-title">
                      {{ os.operation_system }}:
                 {{ os.sessions }}
                      </span></span>
                  </template>
                </div>

                <div class="col-lg-7 mt-lg-0 mt-3 col-12" v-if="statistics.referrers?.length">
                  <h4 class="card-title mb-1" style="color: #354bc1">Referrers</h4>
                  <template :key="referrer.url" v-for="referrer in statistics.referrers">
                    <span class="text-small d-block"><span class="list-title">
                      {{ referrer.url }}:</span> {{ referrer.pageViews }}</span>
                  </template>

                </div>


              </div>

              <div class="row mt-lg-5 mt-3">
                <div class="col-lg-5 col-12" v-if="statistics.browsers?.length">
                  <h4 class="card-title mb-1 card-info-title" style="color: #8c0ac4">Browsers</h4>
                  <template :key="browser.browser" v-for="browser in statistics.browsers">
                    <span class="text-small d-block"><span class="list-title">
                      {{ browser.browser }}:</span> {{ browser.sessions }}</span>
                  </template>
                </div>
                <div class="col-lg-7 mt-lg-0 mt-3 col-12" v-if="statistics.types?.length">
                  <h4 class="card-title mb-1 card-info-title" style="color: #931c36">Types</h4>
                  <template :key="type.type" v-for="type in statistics.types">
                <span class="text-small d-block">
                  <span class="list-title">{{ type.type }}:</span>{{ type.sessions }}
                </span>
                  </template>
                </div>


              </div>
            </div>

            <Loader v-else/>

          </el-card>
        </div>

      </div>
      <div class="col-lg-6 fill-white">
        <dashboard-card
            :isFilterLoading="targetCardToRefetch === 'pageViews' && filterLoader"
            @refetchStatistics="refetchStatistics"
            :isLoading="loading"
            :title="{ar: 'عدد القراءات', key: 'pageViews'}"
            :amount="statistics.pageViews || 0"
        >
          <el-icon>
            <inline-svg src="media/icons/duotune/general/gen032.svg"/>
          </el-icon>
        </dashboard-card>

        <dashboard-card
            :isFilterLoading="targetCardToRefetch === 'sessions' && filterLoader"
            @refetchStatistics="refetchStatistics"
            :isLoading="loading"
            :title="{ar: 'عدد الزوار', key: 'sessions'}"
            :amount="statistics.sessions || 0"
        >
          <el-icon><i class="las la-users"></i></el-icon>
        </dashboard-card>


        <dashboard-card
            :isFilterLoading="targetCardToRefetch === 'published_news' && filterLoader"
            @refetchStatistics="refetchStatistics"
            :isLoading="loading"
            :title="{ar: 'عدد المقالات', key: 'published_news'}"
            :amount="statistics.published_news || 0"
        >
          <el-icon><i class="las la-file-alt"></i></el-icon>
        </dashboard-card>
      </div>


    </div>
  </div>
  <div class="col-lg-6">
    <el-card class="h-100">
      <MostVisitedPages/>
    </el-card>
  </div>
</template>

<script setup>
import DashboardCard from "./DashboardCard";
import {getStatistics, getRealTimeStatistics} from "@/services/dashboard";
import {defineAsyncComponent, onMounted, onUnmounted, ref} from "vue";
import MostVisitedPages from "./MostVisitedPages";
import Loader from "../../../components/Loader";
import DatesFilterDropDown from './DatesFilterDropDown';

let statistics            = ref({})
let active_users          = ref(0)
let loading               = ref(true)
let filterLoader          = ref(false)
const targetCardToRefetch = ref('')

const fetchStatistics = async (filtered_key, refetch = false) => {
  try
  {
    filterLoader.value = refetch
    const result       = await getStatistics(filtered_key);
    statistics.value   = refetch ? targetCardToRefetch.value === 'info' ?
        {
          ...statistics.value,
          referrers       : result.data.referrers,
          operation_system: result.data.operation_system,
          browsers        : result.data.browsers
        }
        : {...statistics.value, [targetCardToRefetch.value]: result.data[targetCardToRefetch.value]} : result.data;
    loading.value      = false
    filterLoader.value = false
    targetCardToRefetch.value = ''
  } catch (e)
  {
    loading.value      = false
    filterLoader.value = false
    console.log(e)
  }

}

const fetchRealTimeStatistics = async () => {
  try
  {
    const result       = await getRealTimeStatistics();
    active_users.value = result.data.active_users
  } catch (e)
  {
    console.log(e)
  }

}

const refetchStatistics = (key, target) => {
  targetCardToRefetch.value = target
  fetchStatistics(key, true)
}

const refetchInfo = (key) => {
  refetchStatistics(key, 'info')
}

const interval = ref()

onMounted(() => {
  fetchStatistics('day');
  fetchRealTimeStatistics()
  interval.value = setInterval(fetchRealTimeStatistics, 15000)
})

onUnmounted(() => clearInterval(interval.value))
</script>

<style lang="scss">
.DashboardCard {
  /*background-image: url("/media/svg/shapes/abstract-5.svg");*/
  /*background-repeat: no-repeat;*/
  /*background-position: left;*/
  /*background-size: cover;*/
}

.el-row {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.fill-white svg {
  fill: #7239ea;
}


.custom-height {
  height: calc(100% - 181px);

  .el-card {
    height: 100%;
  }

  .el-card__body {
    height: 100%;
  }

  .dashboard-card {
    .loader {
      max-height: 280px;
    }
  }
}

span.text-small {
  font-size: .875rem;
}

.list-title {
  margin-right: 8px;
  font-size: 1rem;
  font-weight: 600;
}
</style>
