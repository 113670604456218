<template>
  <el-dropdown ref="dropdown" trigger="click">
    <el-button class="filter_btn">
      <span>{{ filterKey }}</span>
      <el-icon class="el-icon--right">
        <arrow-down/>
      </el-icon>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu class="filter-dropDown">
        <el-dropdown-item v-for="(val,key) in allFilters" :key="key" class="p-0">
            <button class="el-button el-button--text el-button--default" @click.stop="handleSwitch(key)" >
              {{ val }}
            </button>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import {ref} from "vue";

import useDateFilter from "@/composables/useDateFilter";
import {ArrowDown} from "@element-plus/icons-vue";
const props = defineProps(
    {
      defaultKey: {
        type: String,
        default: 'اليوم'
      }
    }
)
const {filterKey, allFilters, switchFilterKey} = useDateFilter(props.defaultKey);

const emit = defineEmits(['triggerFilter']);
const dropdown = ref()
const handleSwitch = (key) => {
  dropdown.value.handleClose()
  switchFilterKey(key)
  emit('triggerFilter',key)
}
</script>

<style lang="scss" scoped>
.filter-dropDown {
  :deep(.el-dropdown-menu__item){
    padding: 0;
  }

  .el-button {
    padding: 5px 16px;
    color: var(--el-text-color-regular);
    display: flex;

    :hover,
    :focus{
      background-color: var(--el-dropdown-menuItem-hover-fill);
      color: var(--el-dropdown-menuItem-hover-color);
    }
  }
}

</style>
