<template>
  <div class="table-filters mb-2">
    <h5 class="table-header">أكثر المقالات مشاهدة </h5>
    <DatesFilterDropDown @triggerFilter="getMostVisitedPages"/>
  </div>
  <div v-if="mostVisitedArticles.length || loading">
    <el-table id="top-table"  :data="mostVisitedArticles" class="mb-4 latest-news" stripe style="width: 100%" max-height="850"
              v-loading="loading">
      <el-table-column type="index" label="#" align="center" width="50"/>
      <el-table-column prop="article" width="480" class-name="text-end" label="المقال">
        <template #default="scope">
          <a :href="scope.row.url" class="news-link w-100" :title="scope.row.pageTitle" target="_blank">{{ scope.row.pageTitle }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="article" align="center" label="عدد المشاهدات" width="130">
        <template #default="scope">
          {{ scope.row.pageViews }}
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div v-else>
    <p>
      لا يوجد بيانات
    </p>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {fetchMostVisitedPages} from "@/services/dashboard";
import DatesFilterDropDown from './DatesFilterDropDown';

const mostVisitedArticles = ref([])
const loading = ref(true);

const getMostVisitedPages = async (key) => {
  loading.value = true
  try{
    const result = await fetchMostVisitedPages(key);
    mostVisitedArticles.value = await result.data
    loading.value = false;
  }catch (e)
  {
    loading.value = false;
    console.log(e)
  }
}

onMounted( () => getMostVisitedPages('day'))
</script>

<style lang="scss">
.filter_btn {
  padding-inline-end: 5px;

  & > span {
    gap: 1rem;
    padding: 5px 0;

    span {
      height: 18px;
    }
  }
}

.dashboard-table a {
  color: #212529;
}
</style>
