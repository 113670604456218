import {ref} from 'vue'

export default function useDateFilter(key = 'اليوم') {
  const filterKey  = ref(key);
  const allFilters = {
    'day'      : 'اليوم',
    'yesterday': 'الأمس',
    'week'     : 'منذ 7 أيام',
    'month'    : 'هذا الشهر',
    'lastMonth': 'الشهر الماضي',
  }

  const switchFilterKey = (key) => {
    filterKey.value = allFilters[key]
  }

  return {filterKey, allFilters, switchFilterKey}
}
