<template>
  <el-card class="box-card dashboard-card">
    <div class="card-text">
      <div class="table-filters align-items-lg-center mb-4">

      <h4 class="card-title">{{ title.ar }}</h4>
        <template v-if="filterable">
          <DatesFilterDropDown  @triggerFilter="fetchStatistics"/>
        </template>
      </div>

      <div class="card-icon d-flex">
        <p v-if="!isFilterLoading && !isLoading">{{ amount }}</p>
        <div class="spinner-border text-primary"  role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
        <slot></slot>
      </div>
    </div>

  </el-card>
</template>

<script setup>
import DatesFilterDropDown from './DatesFilterDropDown';
import {ref} from "vue";
import {getStatistics} from "@/services/dashboard";
import Loader from "../../../components/Loader";

const props = defineProps(
    {
      'title': {
        required:true
      },
      'amount': {
        required:true
      },
      'filterable': {
        default: true
      },
      isLoading: {
        required: true
      },
      isFilterLoading: {
        default: false
      }
    }
)

const emits = defineEmits(['refetchStatistics'])

//const filterLoader = ref(false)

const fetchStatistics =  (filterKey,target = props.title.key) => {

    emits('refetchStatistics',filterKey,target)
}

</script>

<style lang="scss">
.dashboard-card {

  .el-card__body {
    padding: 0;
    color: #181c32;
    height: 164px;

    @media (max-width: 768px) {
      height: unset;
    }
  }

  .card-icon {
    color: #181c32;
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    align-items: center;
    font-size: 3.75em;
    height: 65px;


    .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
      border-width:3px;
    }

  }

  .card-text {
    padding:  1rem;

    h4 {
      font-size: 1.15rem;
      font-weight: bold;
      color: #b5b5c3;
    }

    p {
      font-size: 2.25rem;
      font-weight: 600;
    }
  }
}
</style>
